// import Vue from "vue"
import { createRouter, createWebHashHistory } from "vue-router"
import Gate from "../Gate.vue"
import NotFound from "../Gate.vue"

// Vue.use(Router)

const routes: any[] = [
    {
        path: "/404",
        name: "NotFound",
        component: NotFound,
    },
    {
        path: "/",
        name: "root",
        component: Gate,
    },
    {
        path: "/gate",
        name: "Gate",
        component: Gate,
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
})
export default router
